.collection-wrap{
  display: flex;
  flex-wrap: wrap;
  margin: 30px -15px -15px
}

.collection-wrap h2{
  color: #39343A !important;
  font-size: 24px;
  font-weight: 500;
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;
}

.collection-wrap .box{
  width: calc(25% - 30px);
  margin: 15px;
  background: #fff;
  border-radius: 5px;
  padding: 15px 15px 20px;
  text-align: center;
}

.collection-wrap .box img{
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
}

.collection-wrap h3{
  color: #39343A;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 3px;
}

.collection-wrap p{
  color: #666666 !important;
  font-size: 14px;
}

.profile-wrapper {
  margin-bottom: 40px;
}

.profile-wrapper .inner {
  background: #fff;
  max-width: 1024px;
  border-radius: 8px;
  overflow: hidden;
}

.profile-wrapper .inner .inner-wrap {
  display: flex;
  padding: 32px 32px 24px;
}

.profile-wrapper .inner .inner-wrap .img-wrap, .steps-form-wrapper .step .img-wrap{
  width: 172px;
  height: 172px;
  background: #28B467;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.steps-form-wrapper .step .img-wrap{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: #3c3f47;
}
.steps-form-wrapper .step .upload-img{
  display: flex;
  align-items: center;
}
.steps-form-wrapper .step label{
  display: block;
  margin-right: 20px;
}

.profile-wrapper .inner .inner-wrap .img-wrap > img, .steps-form-wrapper .step  .img-wrap > img {
  width: 100%;
  border-radius: 8px;
  filter: invert();
  width: 50px;
  height: 50px;
  transition: all ease-in-out 0.2s;
}

.steps-form-wrapper .step  .img-wrap > img{
  width: 30px;
  height: 30px;
}
.steps-form-wrapper .step .img-wrap .selected-image img.default-img{
  opacity: 0;
}
.profile-wrapper .inner .inner-wrap .img-wrap:hover, .steps-form-wrapper .step  .img-wrap:hover {
  opacity: 0.7;
}

.profile-wrapper .inner .inner-wrap .img-wrap:hover > img, .steps-form-wrapper .step  .img-wrap:hover > img {
  opacity: 1;
  z-index: 1;
}

.profile-wrapper .inner .inner-wrap .img-wrap input, .steps-form-wrapper .step  .img-wrap input{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  top: 0;
  cursor: pointer;
}

.profile-wrapper .inner .inner-wrap .img-wrap .selected-image, .steps-form-wrapper .step  .img-wrap .selected-image{
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.profile-wrapper .inner .inner-wrap .img-wrap .selected-image img, .steps-form-wrapper .step  .img-wrap .selected-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-wrapper .inner .inner-wrap .right {
  width: calc(100% - 172px);
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.profile-wrapper .inner .inner-wrap .right .content {
  height: 100%;
  width: calc(100% - 332px);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap > img{
  width: 46px;
  margin-right: 10px;
}

.profile-wrapper .inner .inner-wrap .right .content h2 {
  margin-bottom: 16px;
  color: #39343A;
}

.profile-wrapper .inner .inner-wrap .right .content p {
  color: #EBEBEB;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  max-width: 515px;
}

.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap {
  width: 294px;
  display: flex;
  align-items: flex-start;
}

.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap .wallet-address, .collection-wrap .wallet-address{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
    /* border: 1px solid #55cbc6; */

  background: #EAF3F2;
  padding: 11px 15px;
  width: 182px;
}
.collection-wrap .wallet-address{
  width: 100%;
}
.profile-wrapper .inner .inner-wrap .right .wallet-address-wrap .wallet-address span, .collection-wrap .wallet-address p {
  color: #39343A;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  display: inline-block;
  text-overflow: ellipsis;
  width: calc(100% - 25px);
  overflow: hidden;
  white-space: nowrap;
}
.collection-wrap .wallet-address img{
  width: auto !important;
  height: auto !important;
  border-radius: 0 !important;
}

@media screen and (max-width: 1440px){
  .collection-wrap .box{
    width: calc(33.33% - 30px);
  }
}

@media screen and (max-width: 1100px){
  .collection-wrap .box{
    width: calc(50% - 30px);
  }

  .profile-wrapper .inner .inner-wrap {
    padding: 32px;
  }

  .profile-wrapper .inner .profile-bottom {
    margin-top: 0;
  }

  .profile-wrapper .inner .inner-wrap .right .content {
    padding-bottom: 20px;
    width: 100%;
  }

  .profile-wrapper .inner .inner-wrap .right {
    flex-direction: column;
  }
}

@media screen and (max-width: 991px){
  .collection-wrap .box{
    width: calc(33.33% - 30px);
  }
}

@media screen and (max-width: 767px){
  .collection-wrap .box{
    width: calc(50% - 30px);
  }
}

@media screen and (max-width: 575px){
  .collection-wrap .box{
    width: 100%;
  }

  .profile-wrapper .inner .inner-wrap {
    padding: 20px;
    flex-direction: column;
  }

  .profile-wrapper .inner .inner-wrap .img-wrap {
    width: 100%;
    margin-bottom: 20px;
  }

  .profile-wrapper .nav-tabs li {
    width: calc(50% - 20px);
  }

  .profile-wrapper .inner .inner-wrap .right {
    padding-left: 0;
    width: 100%;
  }
}