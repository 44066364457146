/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */
  /* 
font-family: 'Chiller';
font-family: 'Quicksand', sans-serif;
  */
  @import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@300;500;700;800&display=swap");
  /* @font-face {
    font-family: 'Chiller';
    src: url('../fonts/Chiller-Regular.eot');
    src: local('Chiller'), local('Chiller-Regular'),
        url('../fonts/Chiller-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Chiller-Regular.woff2') format('woff2'),
        url('../fonts/Chiller-Regular.woff') format('woff'),
        url('../fonts/Chiller-Regular.ttf') format('truetype'),
        url('../fonts/Chiller-Regular.svg#Chiller-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} */

hr {
  width: 100%;
}

p {
  color: #000000 !important;
}

.Toastify__toast--success {
  background: #42d82e;
}
body {
  font-family: "Inter Tight", sans-serif !important;
}

/* ========== Custom Scroll Styles =========== */
/* 
width
::-webkit-scrollbar {
  width: 7px;
}

Track
::-webkit-scrollbar-track {
  background: #27293d;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #333645;
}

Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: #333645;
} */


/* ========== Custom MainContainer Styles =========== */

.main-container {
  color: white;
  backdrop-filter: blur(4px);
}

.main-container-head {
  display: flex;
  justify-content: space-between;
}

.main-container-heading {
  color: #000000;
  font-size: 18px;
  font-weight: 550;
  margin-top: 10px;
}

/* ========== Custom Table Styles =========== */

.table .rt-tbody .rt-td {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* ========== Custom Slider Styles =========== */

.slider {
  padding: 30px 40px;
  width: 1175px;
}

/*========== Custom Styles View Data  ============= */

.view-data .view-data-main-title {
  font-weight: 500;
  font-size: 26px;
  padding-bottom: 5px;
}

.view-data .view-data-body .view-data-row {
  background-color: #eaeded  ;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
}

.view-data .view-data-body .view-data-row .view-data-title {
  font-weight: 600;
}

.view-data .view-data-body .view-data-sub-title {
  font-size: 20px;
  font-weight: 600;
}

.view-data .view-data-footer {
  display: flex;
  justify-content: center;
}


/*========== Custom Styles Edit-Add Slider ============= */

.edit-add {
  border-radius: 20px;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
}

.edit-add .edit-add-title {
padding: 15px;
font-size: 26px;
font-weight: 500;
text-align: left;
}
.edit-add .edit-add-title h1{
  margin-bottom: 0px;
}

.edit-add .edit-add-sub-title {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
  }

.edit-add .edit-add-body .edit-img {
  width: 120px; 
  height: 120px; 
  border-radius: 50%;
  background: 'gray';
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiFormLabel-root.Mui-focused {
  color: #d82e35;
}

.edit-add .edit-add-body .form.edit-add-field .form-input-field
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d82e35;
}

.edit-add .edit-add-body .edit-add-field .form-input-field
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #6c757d;
}

.MuiAutocomplete-option {
  color: #000000;
}

.edit-add .edit-add-body .edit-add-buttons {
display: flex;
justify-content: center;
width: 100%;
}

/* ========== Custom Button Styles =========== */


.add-btn {
  color: #ffffff !important;
  padding: 8px 15px !important;
  border-radius: 10px !important;
  background: #28B467 !important;
}
button.close {
  opacity: 1 !important;
}
button.close span{
  color: #000;
  font-size: 25px;
}
.view-btn {
  min-width: 45% !important;
  color: white !important;
  border-radius: 6px !important;
  border: 1px solid #333645 !important;

}

.view-btn:hover {
  color: #ffffff !important;
  background: #28B467;
}

.edit-btn {
  min-width: 45% !important;
  color: #d82e35 !important;
  border-radius: 10px !important;
  border: 1px solid #333645 !important;
}

.edit-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
}

.delete-btn {
  min-width: 30% !important;
  color: #d82e35 !important;
  border-radius: 10px !important;
  border: 1px solid #d82e35 !important;
}

.delete-btn:hover {
  color: #ffffff !important;
  background-color: #d82e35 !important;
}

.cancel-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #d82e35  !important;
}

.submit-btn {
    border-radius: 10px !important;
    color: #ffffff !important;
    background: #d82e35  !important;
}

.confirm-btn {
  border-radius: 10px !important;
  color: #ffffff !important;
  background: #d82e35  !important;
}

Button:focus {
    outline: 0px !important; 
}

/* ========== Custom AppLoader Styles =========== */

.loader-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  color: #d82e35;
}

.blue-loader {
  color: #d82e35;
}

/* ========== Main Modal Styles =========== */

.main-modal {
  max-width: 500px !important;
  top: 5%;
}

.main-modal .modal-header,
.modal-footer {
  border: none !important;
}

.main-modal .modal-content {
  background-color: #EAF3F2 !important;
  border-radius: 30px !important;
}

.main-modal .modal-content .modal-header {
  display: flex;                   
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.main-modal .modal-content .modal-header .close {
  color: #fff !important;
}

.main-modal .modal-content .modal-header .modal-logo {
  display: flex;
  justify-content: center;
}

.main-modal .modal-content .modal-header .modal-logo img {
  width: 85%;
  padding: 10px;
  margin: 30px 0px;
  border-radius: 20px;
  background-color: #0f0f0f;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.main-modal .modal-content .modal-body {
  padding: 0px;
}


/* ========== Custom Styles Message Modal =========== */

.message-modal {
  max-width: 1050px !important;
  top: 0% !important;
}

.modal.show .modal-dialog.message-modal {
  transform: translate(0, 5%);
}

.message-modal .message-modal-title p {
  color: #fff !important;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.message-modal .message-modal-line hr {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.message-modal .modal-body .message-text {
  display: flex;
  justify-content: center;
  padding: 25px;
  margin: 25px 0px 80px;
  border-radius: 15px;
  background: rgba(10, 10, 10, 0.7);
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.message-modal .modal-body .message-text h3 {
  margin-bottom: 0px;
}


/*========== Responsive ============= */
@media(max-width:991px){
  .logOutBtnSidebar{
    position: relative !important;
    bottom: unset !important;
  }
  .bgPanel,
  .bgPanel2 {
    display: none;
  }
  .sidebar{
    padding-top: 60px !important;
  }
  .edit-add{
    padding: 20px;
  }
  .main-panel .content {
    padding-left: 0px;
    padding-right: 0px;
}
.sidebar, .off-canvas-sidebar{
  min-width: 0px !important;
}
.navbar .navbar-brand{
  margin-left: 0px !important;
}
.logoHeader img {
  margin-right: 5px !important;
}
.logoHeader div h3 {
  font-size: 13px !important;
}
.logoHeader div p{
  font-size: 10px !important;
}
.detailsAdmin h4{
  font-size: 13px !important;
}
.detailsAdmin p{
  font-size: 10px !important;
}
.navbar .photo img{
  width: 30px !important;
  height: 32px !important;
}
.detailsAdmin {
  margin-right: 5px !important;
}
.navbar{
  padding: 5px 0px 5px 0px !important;
}
.nav-bar .navbar-collapse{
  flex-basis: fit-content;

}
}
@media only screen and (max-width: 650px){

  .slider {
      padding: 30px;
      width: 500px;
  }

  .slider .edit-add {
      padding: 20px 10px;
  }

  .slider .edit-add .slider-body .edit-add-buttons {
      padding: 30px;
  }
}

@media only screen and (max-width: 425px){
  .slider {
      padding: 20px;
      width: 400px;
  }
}

@media only screen and (max-width: 375px){
  .slider {
      padding: 10px;
      width: 325px;
  }
}
.detailsAdmin h4{
  display: flex;
  align-items: center;
}
.detailsAdmin span{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #28B467 !important;
  display: inline-block;
  margin-left: 10px;
}
.detailsAdmin h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1.2em;
  color: #1D2833;
  margin-bottom: 0px;
  text-align: right;
}
.detailsAdmin p {
  color: #999fc2 !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1.1em;
}
.logoHeader img {
  width: 40px;
  margin-right: 10px;
}
.edit-add h1{
  color: #1D2833;
  font-size: 24px;
}
.logoHeader div h3{
  color: white !important;
  font-weight: 800;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}
.logoHeader div p{
font-size: 12px;
color: #999FC2  !important;
opacity: 0.7;
font-weight: 500;
margin-bottom: 0px;
}
.SideBrNContent{
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
}
.sidebar-wrapper ul li.active a{
  background: rgba(255, 255, 255, 0.10) !important;
  border-radius: 5px;
}
.sidebar-wrapper .nav li a p.linkNameSide {
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  text-transform: capitalize;
}
.sidebar-wrapper .nav li a img{
  margin-right: 10px;
}
.InputFieldCustom {
  height: 90px;
  width: 100%;
  border-bottom-width: 2px;
  border-color: rgba(208, 211, 221, 1);
  border-left-width: 2px;
  border-right-width: 2px;
  border-style: solid;
  border-top-width: 2px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 10px;
}
.form-input-field .MuiInputBase-root {
  height: 90px;
  border-bottom-width: 2px;
  border-color: transparent;
  border-left-width: 2px;
  border-right-width: 2px;
  border-style: solid;
  border-top-width: 2px;
  width: 100%;
  border-radius: 10px;
  background-color: #fff !important;
  opacity: 1;
}
.form-input-field .MuiFilledInput-underline:before{
  border-bottom: none;
}
.form-input-field .MuiFilledInput-underline:after{
  border-bottom: none;
}
.form-input-field .MuiFilledInput-root:hover{
  border-color: #55cbc6 !important;
}
.form-input-field .MuiFilledInput-underline:hover:before{
  border-color: transparent;
}
.form-input-field .MuiInputLabel-filled.MuiInputLabel-shrink label{
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Inter Tight", sans-serif !important;
}
.form-input-field .MuiFormLabel-root.Mui-focused{
  color:#3552FA !important;
}
.form-input-field input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #3d3c45 inset !important;
  border-radius: 0px;
}
.form-input-field .labelColorValidator{
  color: #CDD0Da;
}
.form-input-field .MuiInputBase-input{
  color: #000;
}
.edit-add-buttons .submit-btn{
  background: #28B467 !important;
  height: 56px;
  width: 100%;
  font-size: 18px;
}
.mint-new-NFT .edit-add{
max-width:800px;
margin: 0 auto;
}

.mint-new-NFT .edit-add.airdrop{
  max-width: 570px;
}
.table .rt-table{
  background: gray;
}
.bgPanel {
  background-image: url(/images/bgTop-svg.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.63;
  aspect-ratio: 1.55731 / 1;
  flex: 0 0 auto;
  mix-blend-mode: overlay;
  overflow: visible;
  position: absolute;
  width: 590px;
  height: 477px;
  top: 0px;
  right: 0px;
}
.bgPanel2 {
  background-image: url(/images/bgBottomCropped.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  aspect-ratio: 1.55731 / 1;
  bottom: 0px;
  flex: 0 0 auto;
  height: 100px;
  mix-blend-mode: overlay;
  overflow: visible;
  position: absolute;
  width: 890px;
  left: 0px;
}
.logOutBtnSidebar{
  margin: 50px 25px 0;
  border-radius: 0;
  color: #ffffff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  padding: 0;
  position: absolute;
  bottom: 0px;
}
.logOutBtnSidebar button{
  background: transparent;
  color: white;
  border-radius: 0;
  border: 0;
  font-weight: 600;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}
.logOutBtnSidebar img{
  margin-right: 4px;
}
.table .rt-tr-group:nth-child(odd){
  background-color: rgba(0, 0, 0, 0.03);
}
.table .rt-thead .rt-th{
  border-right: none;
}
.table .rt-tbody .rt-td{
  border-right: none;
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
} 
.ReactTable .rt-thead.-filters .rt-th{
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.AllContentSide{
  position: relative;
  z-index: 999;
}
.AllContentSide:before {
  position: absolute;
  content:'';
  width: 280px;
  left: 0;
  top: 0;
  height: 100%;
  background: #1D2833;
}
.verifyBtn{
  border: 0 !important;
  background: #28B467 !important;
}

.table .rt-tr-group{
  background: #fff !important;
}

.ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select{
  background: #E8E9EB !important;
}

.ReactTable .rt-thead{
  background: #fff !important;
}

.ReactTable{
  border: 0;
}

.table .rt-thead .rt-th{
  color: #18181B !important;
  font-weight: bold;
  padding: 15px 10px !important;
}

.table .rt-tbody .rt-td{
  color: #464F58 !important;
}

.airdrop-head{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.airdrop-head span{
  color: #39343A;
  font-size: 15px;
}

.airdrop-wrap  form{
  background: #fff;
  padding: 48px;
}

.airdrop-wrap  input{
  background: #E8E9EB !important;
  border-radius: 5px;
  color: #666666 !important;
  font-size: 16px;
  height: 70px;
  border: 0;
  padding: 20px 35px;
}

.filewrap .upload-btn img {
  margin-right: 5px;
}

.filewrap .upload-btn {
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 13px;
  background: #E8E9EB;
  /* border: 1px solid #55cbc6; */
  border-radius: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 24px;
  color: #666666;
  border-radius: 100px;
  padding: 10px;
  border: 1px solid #666666;
}

.filewrap {
  position: relative;
  background: #E8E9EB;
  border: 0;
  border-radius: 5px;
  height: 70px;
  padding: 20px 35px;
  text-align: left;
  cursor: pointer;
  margin-bottom: 15px;
  cursor: pointer;
}

.filewrap>input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.filewrap span {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #666666;
  line-height: 28px;
}

.filewrap .upload-btn img {
  margin-right: 5px;
}
